function ready(callbackFunction) {
    if (document.readyState != 'loading') {
        callbackFunction(event);
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', callbackFunction);
    } else {
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState == 'complete') {
                callbackFunction(event);
            }
        });
    }
}

ready(function (event) {
    var scrollLinks = document.querySelectorAll('[href^="#"]');
    for (var i = 0; i < scrollLinks.length; i++) {
        scrollLinks[i].addEventListener('click', scrollHandler);
    }
});

function scrollHandler(e) {
    e.preventDefault();

    var collapse = document.getElementsByClassName('collapse show')[0];

    if (collapse) {
        collapse.classList.remove('show');
    }

    var currentPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

    var targetElementId = this.getAttribute('href').split('#')[1];
    var goToPosition = document.getElementById(targetElementId).offsetTop;

    var offset = window.innerWidth >= 992 ? 50 : 120;
    var distance = goToPosition - currentPosition - offset;

    var totalStep = 20;
    var currentStep = 0;
    var intervalTime = 10;

    var scrollby = distance / totalStep;

    var isScrollElementBody =
        document.scrollingElement && document.scrollingElement.tagName == 'BODY';

    var interval = setInterval(function () {
        if (currentStep < totalStep) {
            isScrollElementBody
                ? (document.body.scrollTop += scrollby)
                : (document.documentElement.scrollTop += scrollby);
            currentStep++;
        } else {
            clearInterval(interval);
        }
    }, intervalTime);
}
